const dict = {
  //全局字典
  SETTING: {
    OPEN_PERMISSION: true, //是否开启权限
    API_URL: '/api/', //API具体路径
    IMG_URL: '/api/image/',
    AREA_CODE: 131000,
  },
  passStatus: [
    {value: '1', label: '合格'},
    {value: '2', label: '不合格'},
  ],
  residence: [
    {value: '1', label: '无'},
    {value: '2', label: '本地人'},
    {value: '3', label: '外地人'},
  ],
  vehicleType: [
    {value: 'A1', label: 'A1'},
    {value: 'A2', label: 'A2'},
    {value: 'A3', label: 'A3'},
    {value: 'B1', label: 'B1'},
    {value: 'B2', label: 'B2'},
    {value: 'C1', label: 'C1'},
    {value: 'C2', label: 'C2'},
  ],
  examSub: [
    {value: '1', label: '科目一'},
    {value: '2', label: '科目二'},
    {value: '3', label: '科目三'},
    {value: '4', label: '科目四'},
  ],
  userStatus: [
    {value: '1', label: '启用'},
    {value: '2', label: '停用'},
  ],
  examSubStatus: [
    // examSub1Status - examSub2Status - examSub3Status - examSub4Status
    {value: '1', label: '未约考'},
    {value: '2', label: '已约考'},
    {value: '3', label: '未通过'},
    {value: '4', label: '已通过'},
  ],
  policyFlowStatus: [
    // 赔付状态
    {value: '1', label: '未申请'},
    {value: '2', label: '已提交'},
    {value: '3', label: '已补偿'},
    {value: '4', label: '未通过'},
  ],
  policyStatus: [
    // 服务状态
    {value: '1', label: '已加入'},
    {value: '2', label: '未加入'},
  ],
  templateType: [
    {value: '1', label: '补考费报销会员服务'},
    {value: '2', label: '报名费会员服务'},
  ],
  orderFlowType: [
    // 订单流水结算类型
    {value: '1', label: '系统充值'},
    {value: '10', label: '服务结算'},
    {value: '20', label: '退款结算'},
    {value: '30', label: '补偿结算'},
  ],
  insuranceWay: [
    {value: '1', label: '系统'},
    {value: '2', label: '手动'},
  ],
  compensateStatus: [
    {value: '1', label: '未加入'},
    {value: '10', label: '正常'},
    {value: '20', label: '异常'},
    {value: '30', label: '已达金额上限'},
    {value: '31', label: '已达次数上限'},
    {value: '32', label: '保单到期'},
  ],
  submitStatus: [
    {value: '1', label: '未提交'},
    {value: '2', label: '已提交'},
    {value: '3', label: '已通过'},
    {value: '4', label: '未通过'},
  ],
  submitStatus1: [
    {value: '1', label: '未提交'},
    {value: '2', label: '已提交'},
    {value: '3', label: '已打款'},
    {value: '4', label: '未通过'},
  ],
  errStatus: [
    // {value: '1', label: '未处理'},
    // {value: '2', label: '已处理'},
    {value: '1', label: '系统处理'},
    {value: '2', label: '人工处理'},
  ],
  contractStatus: [
    // 1 已加入  2 保障中  3 金额超限 4 完结 5 已退款
    {value: '1', label: '已加入'},
    {value: '2', label: '保障中'},
    {value: '3', label: '金额超限'},
    {value: '4', label: '已完结'},
    {value: '5', label: '已退出'},
    {value: '10', label: '审核中'},
    {value: '20', label: '已通过'},
    {value: '30', label: '未通过'},
  ],
  returnType: [
    {value: '1', label: '否'},
    {value: '2', label: '是'},
  ],
  missingType: [
    {value: '1', label: '否'},
    {value: '2', label: '是'},
  ],
  logType: [
    {value: '1', label: '登录'},
    {value: '10', label: '新增'},
    {value: '20', label: '编辑'},
    {value: '30', label: '删除'},
    {value: '40', label: '充值'},
    {value: '50', label: '审批'},
    {value: '60', label: '其他'},
  ],
  enrollStatus: [
    {value: 'C', label: '学习中'},
    {value: 'O', label: '已完结'},
    {value: 'Q', label: '已退学'},
  ],
  operationType: [
    {value: '1', label: '登录', detailLabel: '登录'},
    // 新增
    {value: '10', label: '新增', detailLabel: '加入服务'},
    {value: '11', label: '新增', detailLabel: '创建驾校账号'},
    {value: '12', label: '新增', detailLabel: '创建费用模板'},
    // 编辑
    {value: '20', label: '编辑', detailLabel: '编辑驾校'},
    {value: '21', label: '编辑', detailLabel: '修改驾校密码'},
    {value: '22', label: '编辑', detailLabel: '驾校会员服务设置'},
    // 删除
    {value: '30', label: '删除', detailLabel: '费用模板删除'},
    // 充值
    {value: '40', label: '充值', detailLabel: '充值'},
    // 审批
    {value: '50', label: '审批', detailLabel: '平台审核'},
    {value: '51', label: '审批', detailLabel: '异常处理'},
    // 其他
    {value: '60', label: '其他', detailLabel: '服务退款'},
    {value: '61', label: '其他', detailLabel: '生成报名补偿流水'},
  ],
};
try {
  dict.AREA = window.SYS_AREA_INDEX[dict.SETTING.AREA_CODE].data;
} catch (err) {
  console.error('城市编码配置不正确！');
}
/**
 * 生产环境 使用 public目录下 setting.js声明的全局变量
 */
if (process.env.NODE_ENV === 'production') {
  Object.assign(dict.SETTING, window.ADMIN_SETTING);
}

dict.getValue = function (dict, value, key) {
  if (value == null || value == undefined || !dict) {
    return '';
  }
  if (typeof value == 'object') {
    value = value[key];
  }
  var item =
    dict.find((item) => {
      return item.value == value;
    }) || {};
  return item.label || '--';
};
dict.getData = function (dict, value) {
  var item =
    dict.find((item) => {
      return item.value == value;
    }) || {};
  return item || {labe: '--'};
};
export default dict;
