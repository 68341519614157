<template>
  <el-menu :default-active="$route.path" :unique-opened="true" router class="admin-slider-menu">
    <basic-navigator-item v-for="route in rootRoutes.children" :route="route" :key="route.path"></basic-navigator-item>
  </el-menu>
</template>
<script>
import {rootRoutes} from '@/router/routes';
import basicNavigatorItem from './basic-navgator-item';
import {sysConfigGetUntreatedTotal} from '@/api/index';
export default {
  components: {basicNavigatorItem},
  data() {
    return {
      rootRoutes,
      flagsData: null,
    };
  },
  created() {
    this.flagsData = JSON.parse(localStorage.getItem('admin-ui-info-bx-school'));
    if (
      this.flagsData.roleId == 1 ||
      (this.flagsData.permissionIdList.indexOf('30000') > -1 &&
        this.flagsData.permissionIdList.indexOf('40000') > -1 &&
        this.flagsData.permissionIdList.indexOf('110000') > -1)
      // 是超级管理员或者有三个权限请求轮询接口
    ) {
      this.sysConfigGetUntreatedTotalHandler();
    }
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
  methods: {
    sysConfigGetUntreatedTotalHandler() {
      sysConfigGetUntreatedTotal()
        .then((res) => {
          if (res.data.code == 0) {
            this.$store.commit('dot/setState', res.data.data);
          } else {
            return false;
          }
        })
        .finally(() => {
          this.timer = setTimeout(() => {
            this.sysConfigGetUntreatedTotalHandler();
          }, 10000);
        });
    },
  },
};
</script>

<style></style>
