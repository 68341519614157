import ajax from '../ajax.js';
// 上传图片
export const systemUploadImg = (query) => {
  return ajax.post('/sysConfig/uploadImg', query);
};

// 首页
export const serHomeReport = (query) => {
  return ajax.get('/ser/home/report', {
    params: query,
  });
};

export const sysConfigGetUntreatedTotal = (query) => {
  return ajax.get('/sysConfig/getUntreatedTotal', {
    params: query,
  });
};

// 学员
export const drvStudentGetList = (query) => {
  return ajax.get('/drvStudent/getList', {
    params: query,
  });
};

export const drvStudentGetStudent = (query) => {
  return ajax.get('/drvStudent/getStudent', {
    params: query,
  });
};

export const drvStudentGetStudentFlow = (query) => {
  return ajax.get('/drvStudent/getStudentFlow', {
    params: query,
  });
};

export const drvStudentExportStudentFlow = (query) => {
  return ajax.get('/drvStudent/exportStudentFlow', {
    params: query,
    responseType: 'blob',
  });
};

export const drvStudentRefund = (query) => {
  return ajax.post('/drvStudent/refund', query);
};

export const drvStudentExportStudentList = (query) => {
  return ajax.get('/drvStudent/exportStudentList', {
    params: query,
    responseType: 'blob',
  });
};

export const drvStudentGetExpenseRule = (query) => {
  return ajax.get('/drvStudent/getExpenseRule', {
    params: query,
  });
};

export const drvStudentAddPolicy = (query) => {
  return ajax.post('/drvStudent/addPolicy', query);
};

export const drvStudentGetNotPolicyList = (query) => {
  return ajax.get('/drvStudent/getNotPolicyList', {
    params: query,
  });
};

export const drvStudentAddEnrollCompensation = (query) => {
  return ajax.post('/drvStudent/addEnrollCompensation', query);
};

export const drvStudentRefreshStudentExamResult = (query) => {
  return ajax.get('/drvStudent/refreshStudentExamResult', {
    params: query,
  });
};

export const drvStudentForcedOver = (query) => {
  return ajax.post('/drvStudent/forcedOver', query);
};

// 平台审核
export const drvOrderFlowGetCompensationFlowList = (query) => {
  return ajax.get('/drvOrderFlow/getCompensationFlowList', {
    params: query,
  });
};

export const drvOrderFlowGetById = (query) => {
  return ajax.get('/drvOrderFlow/getById', {
    params: query,
  });
};

export const drvOrderFlowConfirmationOfPayment = (query) => {
  return ajax.post('/drvOrderFlow/confirmationOfPayment', query);
};

export const drvOrderFlowGetByIdExcel = (query) => {
  return ajax.get('/drvOrderFlow/getByIdExcel', {
    params: query,
    responseType: 'blob',
  });
};

// 异常信息
export const errMsgGetList = (query) => {
  return ajax.get('/errMsg/getList', {
    params: query,
  });
};

export const errMsgDisposeErr = (query) => {
  return ajax.post('/errMsg/disposeErr', query);
};

export const errMsgGetDisposeList = (query) => {
  return ajax.get('/errMsg/getDisposeList', {
    params: query,
  });
};

// 补偿流水
export const drvOrderFlowGetCompensationFlow = (query) => {
  return ajax.get('/drvOrderFlow/getCompensationFlow', {
    params: query,
  });
};

export const drvOrderFlowExportCompensationFlow = (query) => {
  return ajax.get('/drvOrderFlow/exportCompensationFlow', {
    params: query,
    responseType: 'blob',
  });
};

export const orderFlowExportCompensationFlow = (query) => {
  return ajax.get('/orderFlow/exportCompensationFlow', {
    params: query,
    responseType: 'blob',
  });
};

// 补偿申请单
export const drvApplyCompensationGetList = (query) => {
  return ajax.get('/drvApplyCompensation/getList', {
    params: query,
  });
};

export const drvApplyCompensationGetById = (query) => {
  return ajax.get('/drvApplyCompensation/getById', {
    params: query,
  });
};

export const drvApplyCompensationExportGetById = (query) => {
  return ajax.get('/drvApplyCompensation/exportGetById', {
    params: query,
    responseType: 'blob',
  });
};

export const drvApplyCompensationOrderConfirm = (query) => {
  return ajax.post('/drvApplyCompensation/orderConfirm', query);
};

// 统筹申请单

// 驾校管理
export const drvSchoolGetList = (query) => {
  return ajax.get('/drvSchool/getList', {
    params: query,
  });
};
export const drvSchoolGetSchool = (query) => {
  return ajax.get('/drvSchool/getSchool', {
    params: query,
  });
};

export const drvSchoolUpdSchoolPwd = (query) => {
  return ajax.post('/drvSchool/updSchoolPwd', query);
};
export const drvSchoolAdd = (query) => {
  return ajax.post('/drvSchool/add', query);
};

export const drvSchoolUpdate = (query) => {
  return ajax.post('/drvSchool/update', query);
};

export const drvSchoolSetExpense = (query) => {
  return ajax.post('/drvSchool/setExpense', query);
};

export const drvSchoolRecharge = (query) => {
  return ajax.post('/drvSchool/recharge', query);
};

// 充值列表
export const rechargeFlowGetList = (query) => {
  return ajax.get('/rechargeFlow/getList', {
    params: query,
  });
};

export const rechargeFlowGetById = (query) => {
  return ajax.get('/rechargeFlow/getById', {
    params: query,
  });
};

export const rechargeFlowExportxRechargeFlow = (query) => {
  return ajax.get('/rechargeFlow/exportxRechargeFlow', {
    params: query,
    responseType: 'blob',
  });
};

// 费用模板管理
export const expenseTemplateGetList = (query) => {
  return ajax.get('/expenseTemplate/getList', {
    params: query,
  });
};

export const expenseTemplateAdd = (query) => {
  return ajax.post('/expenseTemplate/add', query);
};

export const expenseTemplateDelete = (query) => {
  return ajax.post('/expenseTemplate/delete', query);
};

export const expenseTemplateGetById = (query) => {
  return ajax.get('/expenseTemplate/getById', {
    params: query,
  });
};

// 数据接口
// 报名数据
export const getEnroll = (query) => {
  return ajax.get('/getEnroll', {
    params: query,
  });
};
// 预约数据
export const getPreasign = (query) => {
  return ajax.get('/getPreasign', {
    params: query,
  });
};
// 理论考试结果数据
export const getTheory = (query) => {
  return ajax.get('/getTheory', {
    params: query,
  });
};
// 科二科三考试结果数据
export const getRoad = (query) => {
  return ajax.get('/getRoad', {
    params: query,
  });
};

// 系统设置
// 修改密码
export const sysUserUpdPassWord = (query) => {
  return ajax.post('/sysUser/updPassWord', query);
};

export const sysUserLogin = (query) => {
  return ajax.post('/sysUser/login', query);
};

export const sysUserLogout = (query) => {
  return ajax.post('/sysUser/logout', query);
};

// 用户登录验证
export const sysUserVerify = (query) => {
  return ajax.post('/sysUser/verify', query);
};

// 获取统筹补偿设置
export const sysConfigUpdCompensation = (query) => {
  return ajax.post('/sysConfig/updCompensation', query);
};

// 获取统筹补偿申请
export const sysConfigGetCompensation = (query) => {
  return ajax.get('/sysConfig/getCompensation', {
    params: query,
  });
};

// 角色管理
export const sysRoleGetList = (query) => {
  return ajax.get('/sysRole/getList', {
    params: query,
  });
};

export const sysRoleAdd = (query) => {
  return ajax.post('/sysRole/add', query);
};

export const sysRoleUpdate = (query) => {
  return ajax.post('/sysRole/update', query);
};

export const sysRoleDelete = (query) => {
  return ajax.post('/sysRole/delete', query);
};

export const sysPermissionGetList = (query) => {
  return ajax.get('/sysPermission/getList', {
    params: query,
  });
};

// 用户管理
export const sysUserGetList = (query) => {
  return ajax.get('/sysUser/getList', {
    params: query,
  });
};

export const sysUserAdd = (query) => {
  return ajax.post('/sysUser/add', query);
};

export const sysUserUpdate = (query) => {
  return ajax.post('/sysUser/update', query);
};

export const sysUserDelete = (query) => {
  return ajax.post('/sysUser/delete', query);
};

// 操作日志
export const operationLogGetList = (query) => {
  return ajax.get('/operationLog/getList', {
    params: query,
  });
};

// 平台审核 - V1.0.4版本
// export const drvCompensateAuditGetList = (query) => {
//   return ajax.get('/drvCompensateAudit/getList', {
//     params: query,
//   });
// };

// export const drvCompensateAuditGetAuditDetails = (query) => {
//   return ajax.get('/drvCompensateAudit/getAuditDetails', {
//     params: query,
//   });
// };

// export const drvCompensateAuditGetDetails = (query) => {
//   return ajax.get('/drvCompensateAudit/getDetails', {
//     params: query,
//   });
// };

// export const drvCompensateAuditUpdateCompensateStatus = (query) => {
//   return ajax.post('/drvCompensateAudit/updateCompensateStatus', query);
// };

// export const drvCompensateAuditSubmitAudit = (query) => {
//   return ajax.post('/drvCompensateAudit/submitAudit', query);
// };

// export const drvCompensateAuditExportCompensateApply = (query) => {
//   return ajax.get('/drvCompensateAudit/exportCompensateApply', {
//     params: query,
//     responseType: 'blob',
//   });
// };

// 统筹审核 - V1.0.4版本
export const drvPolicyApplyGetList = (query) => {
  return ajax.get('/drvPolicyApply/getList', {
    params: query,
  });
};

export const drvPolicyApplyGetAudit = (query) => {
  return ajax.get('/drvPolicyApply/getAudit', {
    params: query,
  });
};

export const drvPolicyApplyDoAudit = (query) => {
  return ajax.post('/drvPolicyApply/doAudit', query);
};

// 服务审核 - V1.0.5版本
export const drvContractApplyGetList = (query) => {
  return ajax.get('/drvContractApply/getList', {
    params: query,
  });
};

export const drvContractApplyCancelAudit = (query) => {
  return ajax.post('/drvContractApply/cancelAudit', query);
};

// 补偿审核
export const drvCompensateApplyGetList = (query) => {
  return ajax.get('/drvCompensateApply/getList', {
    params: query,
  });
};

export const drvCompensateApplyUpdateCompensateStatus = (query) => {
  return ajax.post('/drvCompensateApply/updateCompensateStatus', query);
};

// 确认打款
export const drvCompensateAuditGetList = (query) => {
  return ajax.get('/drvCompensateAudit/getList', {
    params: query,
  });
};

export const drvCompensateAuditGetAuditDetails = (query) => {
  return ajax.get('/drvCompensateAudit/getAuditDetails', {
    params: query,
  });
};

export const drvCompensateAuditGetDetails = (query) => {
  return ajax.get('/drvCompensateAudit/getDetails', {
    params: query,
  });
};

export const drvCompensateAuditSubmitAudit = (query) => {
  return ajax.post('/drvCompensateAudit/submitAudit', query);
};

export const drvCompensateAuditExportCompensateApply = (query) => {
  return ajax.get('/drvCompensateAudit/exportCompensateApply', {
    params: query,
    responseType: 'blob',
  });
};

export const drvContractApplyUpdateAuditResult = (query) => {
  return ajax.post('/drvContractApply/updateAuditResult', query);
};

// 报名统计
export const drvStudentEnrollGetStatistics = (query) => {
  return ajax.get('/drvStudentEnroll/getStatistics', {
    params: query,
  });
};

export const drvStudentEnrollGetStatisticsDetails = (query) => {
  return ajax.get('/drvStudentEnroll/getStatisticsDetails', {
    params: query,
  });
};

export const drvStudentEnrollExportStatisticsDetails = (query) => {
  return ajax.get('/drvStudentEnroll/exportStatisticsDetails', {
    params: query,
    responseType: 'blob',
  });
};
