import basicLayout from '@/layout/basic-layout';

import login from '@/views/login';
//eslint-disable-next-line
const RouteView = {
  name: 'RouteView',
  render: (h) => h('router-view'),
};
//eslint-disable-next-line
const KeepView = {
  name: 'KeepView',
  render: (h) => h('keep-alive', [h('router-view')]),
};
//eslint-disable-next-line
const tagView = require('@/components/tag-view.vue').default;

/**
 * 所有路由中的path 必须为 fullpath 即相对于根目录的绝对路径
 * meta
 *   icon {string} - 栏目图标的class
 *   name {string} - 栏目名称
 *   hide {boolean} - 是否隐藏 （导航）
 *   keep {boolean} - 是否缓存
 *   hideChildren {boolean} -是否隐藏子栏目
 *   p {array} - 权限白名单
 */
let rootRoutes = {
  path: '/',
  redirect: '/home',
  component: basicLayout,
  children: [
    {
      path: '/home',
      name: 'home',
      meta: {
        name: '概览',
        icon: 'iconfont el-icon-s-platform',
        id: '1',
        p: [1],
      },
      component: () => import('@/views/home/home.vue'),
    },
    {
      path: '/student',
      component: () => import('@/views/student/student.vue'),
      meta: {
        name: '学员列表',
        icon: 'iconfont el-icon-s-grid',
        id: '20000',
        p: [20000],
      },
    },
    // 服务审核
    {
      path: '/policy-audit',
      component: () => import('@/views/policy-audit/policy-audit.vue'),
      meta: {
        name: '服务审核',
        icon: 'iconfont el-icon-s-finance',
        serviceFlag: true,
        id: '30000',
        p: [30000],
      },
    },
    // 补偿审核
    {
      path: '/compensation',
      component: () => import('@/views/compensation/compensation.vue'),
      meta: {
        name: '补偿审核',
        icon: 'iconfont el-icon-s-flag',
        compensateFlag: true,
        id: '40000',
        p: [40000],
      },
    },
    // 确认打款
    {
      path: '/confirm-payment',
      component: () => import('@/views/confirm-payment/confirm-payment.vue'),
      meta: {
        name: '确认打款',
        icon: 'iconfont el-icon-s-claim',
        confirmFlag: true,
        id: '100000',
        p: [100000],
      },
    },
    // 驾校管理 - drvSchool
    {
      path: '/drv-school',
      component: () => import('@/views/drv-school/drv-school.vue'),
      meta: {
        name: '驾校管理',
        icon: 'iconfont el-icon-s-management',
        id: '50000',
        p: [50000],
      },
    },
    // 充值列表 - recharge  2023.01.30 修改为驾校余额明细
    {
      path: '/recharge',
      component: () => import('@/views/recharge/recharge.vue'),
      meta: {
        name: '驾校余额明细',
        icon: 'iconfont el-icon-s-data',
        id: '60000',
        p: [60000],
      },
    },
    // 费用设置模板 - expenseTemplate
    {
      path: '/expense-template',
      component: () => import('@/views/expense-template/expense-template.vue'),
      meta: {
        name: '费用设置模板',
        icon: 'iconfont el-icon-s-operation',
        id: '70000',
        p: [70000],
      },
    },
    // 报名统计 - enrollStatistics
    {
      path: '/enroll-statistics',
      component: () => import('@/views/enroll-statistics/enroll-statistics.vue'),
      meta: {
        name: '报名统计',
        icon: 'iconfont el-icon-s-marketing',
        id: '120000',
        p: [120000],
      },
    },
    // 角色 - role
    {
      path: '/role',
      component: () => import('@/views/role/role.vue'),
      meta: {
        name: '角色管理',
        icon: 'iconfont el-icon-s-custom',
      },
    },
    // 用户 - user
    {
      path: '/user',
      component: () => import('@/views/user/user.vue'),
      meta: {
        name: '用户管理',
        icon: 'iconfont el-icon-user-solid',
      },
    },
    // 操作日志 - log
    {
      path: '/log',
      component: () => import('@/views/log/log.vue'),
      meta: {
        name: '操作日志',
        icon: 'iconfont el-icon-date',
        id: '100000',
        p: [100000],
      },
    },
    // 系统设置 - system
    {
      path: '/system',
      component: () => import('@/views/system/system.vue'),
      meta: {
        name: '系统设置',
        icon: 'iconfont el-icon-s-tools',
      },
    },
  ],
};
export {rootRoutes};
const routes = [
  {
    path: '/403',
    name: '403',
    meta: {
      tag: false,
      hide: true,
    },
    component: () => import('@/views/page-403.vue'),
  },
  {
    path: '*',
    meta: {
      tag: false,
      hide: true,
    },
    component: () => import('@/views/page-404.vue'),
  },
  {
    path: '/empty',
    meta: {
      hide: true,
    },
    component: basicLayout,
    children: [
      {
        path: '',
        name: 'empty',
        meta: {
          tag: false,
        },
        component: () => import('@/views/page-empty.vue'),
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: login,
    meta: {
      hide: true,
    },
  },
  rootRoutes,
];

export const routeMap = new Map(); // key<Symbol>:permission<array>
window.routeMap = routeMap;
generateRouteMap(routes);

function generateRouteMap(routes) {
  routes.map((route) => {
    route.meta = route.meta || {};
    route.meta.key = Symbol();
    routeMap.set(route.meta.key, route);
    if (route.children && route.children.length) {
      generateRouteMap(route.children);
    }
  });
}

export default routes;
