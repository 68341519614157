<template>
  <el-date-picker
    :disabled="$props.disabled"
    style="width: 100%"
    v-model="dateRange"
    @change="changeHanlder"
    :start-placeholder="$attrs['start-placeholder'] || '开始日期'"
    :end-placeholder="$attrs['end-placeholder'] || '结束日期'"
    :default-time="$attrs['default-time'] || ['00:00:00', '23:59:59']"
    :type="$attrs['type'] || 'daterange'"
    :value-format="$attrs['value-format'] || 'timestamp'"
    v-bind.prop="$attrs"
    v-on="$listeners"></el-date-picker>
</template>

<script>
export default {
  created() {
    this.setVal();
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    digit: {
      type: Number,
      default: 10,
    }, //位数、默认13位时间戳
    begin: [String, Number], //开始 10位时间戳
    end: [String, Number], //结束 10位时间戳
  },
  data() {
    return {
      dateRange: [],
    };
  },
  watch: {
    '$props.begin': function () {
      this.setVal();
    },
    '$props.end': function () {
      this.setVal();
    },
  },
  methods: {
    changeHanlder(newVal) {
      let s = this.$props.digit == 13 ? 1 : 1000;
      newVal = newVal || [];
      let begin = newVal[0] ? parseInt(newVal[0] / s) : '';
      let end = newVal[1] ? parseInt(newVal[1] / s) : '';
      this.$emit('update:begin', begin);
      this.$emit('update:end', end);
    },
    setVal() {
      if (this.$props.begin && this.$props.end) {
        let s = this.$props.digit == 13 ? 1 : 1000;
        this.dateRange = [this.$props.begin * s, this.$props.end * s];
        this.$emit('input', this.dateRange);
      } else {
        this.dateRange = [];
      }
    },
  },
};
</script>

<style></style>
