<template>
  <select-dict-remote
    :query="{getType: $props.getType}"
    :api="api"
    value-field="drvSchoolCode"
    label-field="drvSchoolForShort"
    v-bind="$attrs"
    v-on="$listeners"></select-dict-remote>
</template>

<script>
import {drvSchoolGetList} from '@/api/index';
export default {
  props: {
    getType: Number, // 1 需要隐藏已存在账号驾校  2 无需
  },
  data() {
    return {
      api: drvSchoolGetList,
    };
  },
};
</script>

<style></style>
