// import {
//   setAreaRange
// } from "@/utils";
export default {
  namespaced: true,
  state: {
    serviceAuditTotal: '', //  服务审核条数
    compensateAuditTotal: '', //  补偿审核条数
    confirmAuditTotal: '', //  确认审核条数
  },
  mutations: {
    setState(state, obj) {
      state.serviceAuditTotal = obj.serviceAuditTotal;
      state.compensateAuditTotal = obj.compensateAuditTotal;
      state.confirmAuditTotal = obj.confirmAuditTotal;
    },
  },
  actions: {},
  getters: {},
};
